<template>
  <div>
    <b-modal
      id="modal-walking-customer"
      ref="modal"
      v-model="open"
      size="lg"
      no-close-on-backdrop
      hide-footer
      :title="modalTitle"
    >
      <b-overlay
        :show="loading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <ValidationObserver
          ref="walkingCustomerForm"
          v-slot="{ handleSubmit }"
          slim
        >
          <b-form
            class="my-8"
            @submit.prevent="handleSubmit(handleFormSubmit)"
          >
            <b-row>
              <b-col cols="12">
                <b-form-group
                  class="required"
                  label="NIC/ Passport Number/ License Number"
                  label-for="h-branchName"
                  label-cols-md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="NIC/ Passport Number/ License Number"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="form.id_no"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Enter NIC/ Passport Number/ License Number Here!!"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  class="required"
                  label="Name"
                  label-for="h-branchName"
                  label-cols-md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="form.name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Enter Name Here!!"
                      type="text"
                      autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  class="required"
                  label="Address"
                  label-for="h-pAddress"
                  label-cols-md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="Primary Address"
                    rules="required"
                  >
                    <b-form-textarea
                      id="h-pAddress"
                      v-model="form.address"
                      :state="errors.length > 0 ? false:null"
                      size="sm"
                      placeholder="Enter Address Here!"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  class="required"
                  label="Phone"
                  label-for="h-pEmail"
                  label-cols-md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="Email"
                    rules="required"
                  >
                    <b-form-input
                      id="h-pEmail"
                      v-model="form.phone"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Enter Phone Here!!"
                      type="number"
                      autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <!-- submit and reset -->
              <b-col
                offset-md="4"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  class="mr-1 custom-button-color"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="clearAll"
                >
                  Clear
                </b-button>
              </b-col>
            </b-row>

          </b-form>
        </ValidationObserver>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BFormTextarea,
  BRow,
  VBModal,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const WalkingCustomerRepository = RepositoryFactory.get('walkingCustomer')

function initialState() {
  return {
    open: false,
    id: null,
    modalPurpose: 'add',
    loading: false,
    form: {},
  }
}
export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BFormTextarea,
    BRow,
    BOverlay,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  computed: {
    modalTitle() {
      return this.modalPurpose === 'add' ? 'ADD NEW WALKING CUSTOMER' : 'UPDATE WALKING CUSTOMER'
    },
  },
  methods: {
    async openModal(id, purpose) {
      await Object.assign(this.$data, initialState())
      this.loading = true
      this.open = true
      this.modalPurpose = purpose
      this.id = id
      if (this.modalPurpose === 'edit') {
        await this.fetchWalkingCustomerDetails(this.id)
      }
      this.loading = false
    },
    async fetchWalkingCustomerDetails(id) {
      try {
        const { data } = (await WalkingCustomerRepository.getWalkingCustomerById(id)).data
        this.form = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async handleFormSubmit() {
      this.loading = true
      try {
        const payload = this.form
        let res
        if (this.modalPurpose === 'add') {
          res = await await WalkingCustomerRepository.createWalkingCustomer(payload)
        } else {
          res = await await WalkingCustomerRepository.updateWalkingCustomer(this.id, payload)
        }
        if (res.status === 200 || res.status === 201) {
          localStorage.removeItem('walking_customer_list')
          this.showSuccessMessage(`Walking Customer ${this.modalPurpose === 'add' ? 'Added' : 'Updated'} Successfully`)
          this.open = false
          this.$parent.onClickRefresh()
        } else {
          this.showErrorMessage(`Failed to ${this.modalPurpose === 'add' ? 'Add' : 'Update'} Walking Customer`)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    clearAll() {
      this.form = {}
    },
  },
}
</script>
<style scoped>
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>

<template>
  <div>
    <div>
      <b-card-actions
        title="Filters"
        action-collapse
        collapsed
      >
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Name"
              label-for="v-name"
            >
              <b-form-input
                id="v-name"
                v-model="filters['name']"
                placeholder="Search"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Address"
              label-for="v-ref-no"
            >
              <b-form-input
                id="v-ref-no"
                v-model="filters['address']"
                placeholder="Search"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Apply and Clear Filters -->
        <b-row class="mt-1">
          <b-col>
            <filter-buttons
              :filter-data="filters"
              :apply-filters="applyFilters"
              :clear-filters="clearFilters"
            />
          </b-col>
        </b-row>
      </b-card-actions>
    </div>
    <div>
      <b-card
        no-body
      >
        <div
          class="m-2"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <b-button
                v-can="'create-walking-customer'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="custom-button-color"
                @click="onClickCreate"
              >
                <feather-icon icon="PlusIcon" />
                Add New
              </b-button>
            </b-col>
            <b-col
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
            >
              <ReportTableButtons
                :items="items"
                :json_fieldz="json_fields"
                :name="`Walking Customer - Page ${page}.xls`"
                :bulkname="`Walking Customer.xls`"
                :fetch="fetchDataForWalkingCustomerNoPagination"
                @refresh="onClickRefresh"
              />
            </b-col>
          </b-row>
        </div>

        <!-- table -->
        <b-overlay
          id="overlay-background"
          blur="10px"
          opacity="0.30"
          rounded="sm"
          variant="light"
        >
          <b-table
            :per-page="perPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            hover
            responsive
            striped
            show-empty
            sticky-header="100vh"
          >
            <template #empty>
              <TableDataFetching
                :rows="items"
                :data-loading="dataLoading"
              />
            </template>
            <template #cell(id)="data">
              <div class="d-flex align-items-center">
                <b-avatar
                  size="32"
                  variant="light-danger"
                  :text="avatarText(data.item.name)"
                />
                <div>
                  <div class="font-weight-bolder ml-2">
                    {{ data.item.name }}
                  </div>
                  <div class="font-small-2 ml-2">
                    {{ data.item.phone }}
                  </div>
                </div>
              </div>
            </template>

            <template #cell(action)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="12"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-can="'show-walking-customer'"
                  v-b-modal="'modal-update-branch'"
                  @click="onClickUpdate(data.item.id)"
                >
                  <feather-icon icon="ToolIcon" />
                  Manage Walking Customer
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-overlay>

        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-sm-start"
            >
              <label>Per page</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-1 width-100 mr-1"
              />
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              md="9"
              class="d-flex align-items-center justify-content-sm-end"
            >
              <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
              <b-pagination
                v-model="page"
                :total-rows="(meta.total)? meta.total : 0"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
    <walking-customer-modal ref="walking_customer_modal" />
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  VBModal,
  VBTooltip,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import { avatarText } from '@core/utils/filter'
import store from '@/store/index'
import WalkingCustomerModal from '@/components/walking-customer/walking-customer-modal.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import FilterButtons from '@/components/Filter/FilterButtons.vue'

const WalkingCustomerRepository = RepositoryFactory.get('walkingCustomer')

export default {
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    vSelect,
    BOverlay,
    BCard,
    BCardActions,
    BTable,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BPagination,
    BButton,
    BFormInput,
    BDropdown,
    BDropdownItem,
    WalkingCustomerModal,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      avatarText,
      filters: {},
      id: null,
      // Start Add New Modal Properties
      dataLoading: false,
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      meta: {},
      dir: false,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'id',
          label: 'Name',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        { key: 'id_no', label: 'ID Number', sortable: true },
        { key: 'address', label: 'Address', sortable: true },
        { key: 'action', label: 'Action', sortable: false },
      ],
      json_fields: {
        Name: {
          field: 'name',
          callback: value => `"${value}"`,
        },
        Phone: 'phone',
        'ID Number': {
          field: 'id_no',
          callback: value => `"${value}"`,
        },
        Address: {
          field: 'address',
          callback: value => `"${value}"`,
        },
      },
      items: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchDataForWalkingCustomer()
      },
    },
    deep: true,
    page() {
      this.fetchDataForWalkingCustomer()
    },
    perPage() {
      this.fetchDataForWalkingCustomer()
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.fetchDataForWalkingCustomer()
  },
  methods: {
    async fetchDataForWalkingCustomer() {
      this.dataLoading = true
      this.items = []
      try {
        const { data } = (await WalkingCustomerRepository.getWalkingCustomerList(this.page, this.filterQuery, this.perPage, this.sort))
        this.items = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async fetchDataForWalkingCustomerNoPagination() {
      try {
        const { data } = (await WalkingCustomerRepository.getWalkingCustomerListNoPagination(this.filterQuery, this.sort))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    onClickCreate() {
      this.$refs.walking_customer_modal.openModal(null, 'add')
    },
    onClickUpdate(id) {
      this.$refs.walking_customer_modal.openModal(id, 'edit')
    },
    handleChangePage(page) {
      this.page = page
      this.fetchDataForWalkingCustomer()
    },
    applyFilters() {
      this.fetchDataForWalkingCustomer()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.fetchDataForWalkingCustomer()
      })
    },
    onClickRefresh() {
      this.fetchDataForWalkingCustomer()
    },
  },
}
</script>
<style lang="scss">
@import '../../@core/scss/vue/libs/vue-select.scss';
@import "../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
</style>
<style lang="scss" scoped>
@import '@core/scss/vue/required.scss';
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
